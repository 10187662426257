<template>
  <span class="v-logo__container" :class="{ 'v-logo__container--mobile': mobile }">
    <span class="v-logo" :class="{ blur: blur }">
      <span class="v-logo__circle">T
        <span v-if="crown" class="v-logo__crown">
          <img src="@/assets/icon/crown.svg?url" alt="" />
        </span>
      </span>

      <span class="v-logo__host">{{ host }}</span>
      <span class="v-logo__domain" v-if="domain">{{ domainz }}</span>
    </span>
    <span class="v-logo v-logo__shadow" v-if="!mobile">
      <span class="v-logo__circle">T</span>
      <span class="v-logo__host">{{ host }}</span>
      <span class="v-logo__domain" v-if="domain">{{ domainz }}</span>
    </span>
  </span>
</template>

<script setup>
import IconLogo from "@/assets/svg/logo.svg?component"
const host = window.location.hostname.split(".")[0];
const domainz = window.location.hostname.split(".")[1];

defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
  domain: {
    type: Boolean,
    default: true,
  },
  blur: {
    type: Boolean,
    default: false,
  },
  crown: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss">
.v-logo {
  display: flex;
  align-items: center;
  justify-content: center;


  &.blur {
    filter: blur(3px);
  }

  &__container {
    position: relative;
    margin-top: 25px;
    display: flex;

    &--mobile {
      margin-top: 0px;
    }
  }

  &__circle {
    background: radial-gradient(73.33% 73.33% at 50% 50%, #9db1ff 0%, #6d3eff 100%);
    width: 50.29px;
    height: 50.23px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: 7px;
    position: relative;
  }

  &__crown {
    position: absolute;
    top: -55%;
    right: -38%;
    width: 100%;
    z-index: 20;
  }

  &__host {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    /* identical to box height */

    letter-spacing: -0.01em;

    color: #2c2d33;
    margin-right: 4px;
  }

  &__domain {
    background: linear-gradient(90deg, #ffc658 -18.18%, #f4a100 49.18%, #ffc658 120%);
    border-radius: 100px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */

    color: #2c2d33;
    padding: 6px 16px 7px 16px;
  }

  &__shadow {
    width: 100%;
    height: 100%;
    filter: blur(10px);
    position: absolute !important;
    left: 0;
    opacity: 0.2;
    right: 0;
    bottom: -10px;
    z-index: -1;
  }
}

.rheader__logo.v2 {
  .v-logo {

    //transform: scale(0.8);
    //transform-origin: left center;
    &__circle {
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }

    &__crown {
      display: none;
    }

    &__host {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      /* identical to box height */

      color: #2c2d33;
      margin-right: 5px;
    }

    &__domain {
      padding: 7px 13px 8px 13px;
    }
  }
}

.header-mobile .v-logo__container--mobile {
  .v-logo {
    &__crown {
      display: flex;
    }
  }
}

.v-logo__container--mobile {
  .v-logo {
    position: relative;

    &__crown {
      display: none;
    }

    &__circle {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      font-size: 16px;
    }

    &__host,
    &__domain {
      display: none;
    }

    &__crown {
      height: 27px;
      right: -30%;
      top: -51%;
      width: 27px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.redesign .new-navbar {
  .v-logo__container--mobile .v-logo__circle {
    font-size: 28px;
  }
}

.theme__dark {
  .v-logo {
    &__svg {
      path {
        fill: #B6B0D7
      }
    }
  }
}
</style>
